<template>
  <section class="introduction">
    <div class="introduction_img1"><img src="@/assets/img/tonbo.svg" /></div>
    <p class="title">GRAPHIC DESIGN / WEBDEV</p>
    <h2>WITCHKRAFT</h2>
    <h3 class="job">Designer / Web Developer / Frontend Engineer</h3>
    <h3 class="service">デザイン / WEB制作 / 印刷 ならお任せください</h3>
    <h4 class="ja">
      ウィッチクラフト
      グラフィックデザインは、熊本県天草市を拠点に活動するデザインオフィスです。
      <br />
      チラシや名刺など印刷物のデザインから、WEB制作、ホームページのデザインまで、企業様、個人様問わずお引き受けしております。
    </h4>
    <p class="en">
      WITCHKRAFT GRAPHIC DESIGN is a design office based in Amakusa City,
      Kumamoto Pref, JAPAN.
    </p>
  </section>
</template>
<style scoped lang="scss">
.introduction {
  @include section;
  padding-top: $top_padding;
  max-width: none;
  position: relative;
  z-index: 1;
  .introduction_img1 {
    position: absolute;
    padding-top: $top_padding;
    top: 0;
    right: 0;
    transform: translateX(6%);
    width: clamp(0px, 24%, 21rem);
    z-index: 0;
    img {
      width: 100%;
      height: auto;
    }
  }
  .introduction_img2 {
    bottom: 0;
    right: 0;
    width: 10rem;
    z-index: -2;
    margin-top: 2rem;
    img {
      width: 100%;
    }
  }
  .title {
    font-size: clamp(1rem, 1.5vw, 1.5rem);
    font-weight: 600;
    margin-bottom: 1em;
  }
  h2 {
    font-family: $headline;
    font-weight: 600;
    font-size: clamp(6rem, 10vw, 12rem);
    max-width: 75%;
    line-height: 0.8em;
    margin-bottom: 0.25em;
  }
  h3 {
    font-size: clamp(1rem, 1.5vw, 1.5rem);
    font-weight: 600;
    line-height: 1.2em;
    margin-bottom: 1.5em;
    span {
      display: inline-block;
      white-space: pre-wrap;
    }
    &.service {
      padding-top: 1.5em;
      margin-bottom: 1em;
      position: relative;
      &::after {
        display: block;
        content: "";
        position: absolute;
        width: clamp(80px, 80%, 240px);
        height: 1px;
        background-color: $fontcolor;
        top: 0;
      }
    }
  }
  h4,
  p {
    font-weight: 600;
    max-width: clamp(360px, 80%, 800px);
    line-height: 1.5;
    font-size: 0.8rem;
    margin-bottom: 1em;
    span {
      display: inline-block;
      white-space: pre-wrap;
    }
    &.ja {
      line-height: 2;
    }
  }
}
</style>
